import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['nl', 'de', 'en', 'fr', 'original', 'localeTab']
  static values = { url: String, all: Boolean, copy: Boolean, noscroll: Boolean, confirmOverwrite: String }

  connect() {
  }

  en() {
    this.translate('en', this.enTarget.value);
  }

  fr() {
    this.translate('fr', this.frTarget.value);
  }

  de() {
    this.translate('de', this.deTarget.value);
  }

  all(event) {
    if (this.noscrollValue) event.preventDefault();

    if (this.hasConfirmOverwriteValue && !confirm(this.confirmOverwriteValue)) {
      return;
    }    

    if (this.copyValue) {
      this.copy();
    } else if(this.allValue) {
      this.translateAll();
    } else {
      this.en();
      this.fr();
      this.de();
    }
  }

  copy() {
    ["nl", "en", "fr", "de"].forEach((locale) => {
      if (this[`${locale}Target`] != this.originalTarget && !this[`${locale}Target`].value.trim()) {
        this[`${locale}Target`].value = this.originalTarget.value;
      }
    });
  }

  translateAll(event) {
    let that = this;
    let url = `${this.urlValue}?original_text=${encodeURIComponent(this.originalTarget.value)}`;
    this.visualConfirmationTranslationStarted()
    fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.json()).then((data) => {
      Object.keys(data).forEach((locale) => {
        if (that[`${locale}Target`].value.trim().length == 0 || this.hasConfirmOverwriteValue) {
          that[`${locale}Target`].value = data[locale];
        }
      });
      this.visualConfirmationTranslationCompleted();
    });
  }

  translate(to, target) {
    let url = this.urlValue;
    url += `?from=nl&`;
    url += `to=${to}&`;
    url += `original_text=${encodeURIComponent(this.nlTarget.value)}`;

    fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.locale == 'en') {
          this.enTarget.value = data.result;
        }

        if (data.locale == 'fr') {
          this.frTarget.value = data.result;
        }

        if (data.locale == 'de') {
          this.deTarget.value = data.result;
        }
      });
  }

  visualConfirmationTranslationStarted() {
    if (!this.hasLocaleTabTarget) return;
    this.localeTabTargets.forEach((tab) => {
      tab.style.backgroundColor = "#FFA500";
    });
  }

  visualConfirmationTranslationCompleted() {
    if (!this.hasLocaleTabTarget) return;
    this.localeTabTargets.forEach((tab) => {
      tab.hidden = false;
      tab.style.transition = "background-color 0.5s ease-out";
      tab.style.backgroundColor = "#A8E6A1";

      setTimeout(() => {
        tab.style.backgroundColor = "";
      }, 2000);
    });
  }

}
