import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['label'];
  static values = {
    sourceSelector: String,
    visible: Array 
  }

  connect() {
    const controller = this;
    this.sourceField = document.querySelector(this.sourceSelectorValue);
    this.sourceField.addEventListener('change', (e) => controller.toggleElement());

    if (this.hasLabelTarget) {
      this.originalLabel = this.labelTarget.innerHTML;
    }
    this.toggleElement();
  }

  toggleElement() {
    let visibleValue = this.visibleValue.find((v) => v[0] == this.sourceField.value);

    if (this.hasLabelTarget) {
      if (visibleValue && visibleValue[1]) {
        this.labelTarget.innerHTML = visibleValue[1];
      } else {
        this.labelTarget.innerHTML = this.originalLabel;
      }
    }

    this.element.classList.toggle('d-none', !visibleValue);
  }
}
