import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['selector', 'button', 'quantity', 'buttonText', 'defaultText', 'quantityText', 'minus', 'remove']

  static timeout;

  connect() {
    this.hideSelector();

    window.addEventListener('new-cart-quantity', (e) => {
      this.quantityTarget.value = e.detail.quantity;
      this.updateQuantity();
    });

    window.addEventListener('add-to-cart-button-updated', (e) => {
      this.updateButtonStyling(e.detail.classList);
    });
  }

  showSelector(e) {
    e.preventDefault();

    this.buttonTarget.hidden = true;
    this.selectorTarget.hidden = false;

    if (this.quantityTarget.value == 0) {
      this.quantityTarget.value = 1;
    }
    this.updateButtons();
    this.refreshTimeout(5000);
  }

  hideSelector() {
    if (this.hasSelectorTarget) {
      this.selectorTarget.hidden = true;
    }
    if (this.hasButtonTarget) {
      this.buttonTarget.hidden = false;
    }
  }

  refreshTimeout(timer = 2000) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(function() {
      this.hideSelector();
      this.updateQuantityEvent();
      this.updateQuantity();
      this.addToCart();
    }.bind(this), timer);
  }

  up(e) {
    e.target.parentNode.querySelector('input[type=number]').stepUp();
    this.quantityTarget.value = e.target.parentNode.querySelector('input[type=number]').value;

    this.updateButtons();
    this.refreshTimeout();
  }

  down(e) {
    let parentNode;
    if (e.target.tagName == 'path') {
      parentNode = e.target.parentNode.parentNode.parentNode;
    } else if (e.target.tagName == 'svg') {
      parentNode = e.target.parentNode.parentNode;
    } else {
      parentNode = e.target.parentNode;
    }

    parentNode.querySelector('input[type=number]').stepDown();
    this.quantityTarget.value = parentNode.querySelector('input[type=number]').value;

    this.updateButtons();

    if (this.quantityTarget.value == 0) {
      this.refreshTimeout(0);
    } else {
      this.refreshTimeout();
    }
  }

  updateButtons() {
    if (this.quantityTarget.value == 1) {
      this.minusTarget.hidden = true;
      this.removeTarget.hidden = false;
    } else {
      this.minusTarget.hidden = false;
      this.removeTarget.hidden = true;
    }
  }

  updateButtonStyling(classList) {
    this.buttonTarget.classList = classList;
  }

  updateQuantityEvent() {
    window.dispatchEvent(
        new CustomEvent('new-cart-quantity',
            {
              detail: {
                quantity: this.quantityTarget.value,
              },
            },
        ),
    );
  }

  updateQuantity() {
    if (this.quantityTarget.value == 0) {
      this.buttonTextTarget.textContent = this.defaultTextTarget.value;
    } else {
      this.buttonTextTarget.textContent = this.quantityTextTarget.value.replace('%{count}', this.quantityTarget.value);
    }
  }

  addToCart() {
    window.dispatchEvent(new CustomEvent('add-to-cart'));
  }
}
