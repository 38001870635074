import {Controller} from 'stimulus';

export default class ToggleController extends Controller {
  static targets = ['content', 'selectedIndicator'];

  connect() {
    // toggle when last state was stored as 'on' in cookie
    if (this.data.has('cookie') && document.cookie.indexOf(this.data.get('cookie')+'=true') != -1) {
      this.toggle();
    }

    if (this.data.get('disabled') == 'true') {
      this.disableAllFields();
    }
    if (this.data.get('disable-hidden') == 'true') {
      this.contentTargets.forEach((t) => {
        this.disableFieldsIfHidden(t);
      });
    }

    if (this.data.has('only-open-for-hash')) {
      this.open();
    }
  }

  toggle(e) {
    if (this.hasSelectedIndicatorTarget) {
      const classToToggle = this.data.get('selected-indicator-class') || 'active';
      this.selectedIndicatorTarget.classList.toggle(classToToggle);
    }

    if (this.data.has('body-class')) {
      document.body.classList.toggle(this.data.get('body-class'));
    }

    const backdrops = document.getElementsByClassName('filter-backdrop');
    if (backdrops.length) {
      backdrops[0].remove();
    }

    if ( e && e.currentTarget && e.currentTarget.dataset.backdropEnabled == 'true' ) {
      const filterBackdrop = document.createElement('div');
      filterBackdrop.className = 'filter-backdrop';
      const container = document.querySelector('.filter-bar');
      if (container) {
        container.appendChild(filterBackdrop);
      } else {
        document.body.appendChild(filterBackdrop);
      }
      filterBackdrop.addEventListener('click', () => {
        this.toggle(e);
      });
    }

    this.contentTargets.forEach((t) => {
      if (this.data.get('enable')) {
        t.disabled = false;
      } else {
        t.classList.toggle(this.data.get('class'));
      }
      if (this.data.get('disable-hidden-fields')) {
        this.disableFieldsIfHidden(t);
      }

      if (this.data.has('cookie')) {
        const maxAge = 60*60; // 1 hour
        // store current state of toggle in configured cookie
        document.cookie = this.data.get('cookie')+'='+t.classList.contains(this.data.get('class'))+
                          '; path=/ ;max-age=' + maxAge;
      }

      if (t.classList.contains(this.data.get('animation'))) {
        setTimeout(() => {
          t.classList.remove(this.data.get('animation'));
        }, 250);
      } else {
        t.classList.add(this.data.get('animation'));
      }
    });

    if (e && !this.data.get('propagate')) {
      if (this.data.has('hideLink')) {
        e.target.classList.add('d-none');
      }
      e.preventDefault();
    }
  }

  open() {
    if (this.data.has('only-open-for-hash')) {
      if (location.hash != '#'+this.data.get('only-open-for-hash')) {
        return false;
      }
    }

    if (this.hasSelectedIndicatorTarget) {
      const classToToggle = this.data.get('selected-indicator-class') || 'active';
      this.selectedIndicatorTarget.classList.add(classToToggle);
    }

    this.contentTargets.forEach((t) => {
      t.classList.add(this.data.get('class'));

      if (this.data.has('cookie')) {
        // store current state of toggle in configured cookie
        document.cookie = this.data.get('cookie')+'='+t.classList.contains(this.data.get('class'))+
                          '; path=/ ;expires=Fri, 31 Dec 9999 23:59:59 GMT';
      }
    });
  }

  disableFieldsIfHidden(contentTarget) {
    if (contentTarget.classList.contains(this.data.get('class'))) {
      this.disableFields(contentTarget);
    } else {
      this.enableFields(contentTarget);
    }
  }

  disableAllFields() {
    this.element.querySelectorAll('input').forEach((e) => e.disabled = true);
    this.element.querySelectorAll('select').forEach((e) => e.disabled = true);
  }

  disableFields(target) {
    target.querySelectorAll('input').forEach((e) => e.disabled = true);
    target.querySelectorAll('select').forEach((e) => e.disabled = true);
  }

  enableFields(target) {
    target.querySelectorAll('input').forEach((e) => e.disabled = false);
    target.querySelectorAll('select').forEach((e) => e.disabled = false);
  }

  on() {
    this.contentTargets.forEach((t) => {
      t.classList.add(this.data.get('class'));
    });
  }

  off() {
    this.contentTargets.forEach((t) => {
      t.classList.remove(this.data.get('class'));
    });
  }

  toggleAll() {
    this.contentTargets.forEach((t) => {
      t.classList.toggle(this.data.get('class'));
    });
  }
}
