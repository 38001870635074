import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ["result"]

  async share(event) {
    event.preventDefault();

    const shareData = {
      title: this.data.get("title"),
      // text: "I found this amazing content.",
      url: this.data.get("url")
    };

    if (!navigator.share) {
      return;
    }

    try {
      await navigator.share(shareData);
    } catch (err) {
    }
  }
}
