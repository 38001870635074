import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    const newLabel = this.data.get('newLabel') ? `(${this.data.get('newLabel')})` : "";
    const options = {
      language: this.data.get('locale') || 'en',
      tags: (this.data.get('extensible') == 'true'),
      allowClear: true,
      templateResult: (data) => {
        if (data.loading) return data.text;
        
        // If this is a new tag (either from createTag or direct input)
        if (data.newTag || (data.id == data.text && data.element === undefined)) {
          return $(`<span><i>${data.text} ${newLabel}</i></span>`);
        }
        
        return data.text;
      },
      createTag: function(params) {
        const term = params.term?.trim() || '';
        
        if (term === '') {
          return null;
        }
        
        return {
          id: `NEW_${term}`,
          text: term,
          newTag: true
        };
      }
    };

    if (this.data.get('minInput') != 0) {
      options['minimumInputLength'] = this.data.get('minInput') || 2;
    }

    if (this.data.get('placeholder')) {
      options['placeholder'] = this.data.get('placeholder');
      options['allowClear'] = true;
    }
    if (this.data.get('url')) {
      options['ajax'] = {
        url: this.data.get('url'),
        dataType: 'json',
        data: (params) => ({q: params.term, page: params.page || 1}),
        delay: 500,
      };
    }
    $(this.element).select2(options);

    
    if ($(this.element).hasClass("new")) {
      $(this.element).select2("focus");
    }

    // workaround to fire native events for select2's own events
    $(this.element).on('select2:select', function() {
      this.dispatchEvent(new Event('change', {bubbles: true})); // fire a native event
    });
    $(this.element).on('select2:open', function() {
      this.dispatchEvent(new Event('focusin', {bubbles: true})); // fire a native event
    });
    $(this.element).on('select2:close', function() {
      this.dispatchEvent(new Event('focusout', {bubbles: true})); // fire a native event
    });
    let clearing = false;
    $(this.element).on('select2:clearing', function () {
      clearing = true;
    });
    $(this.element).on('select2:opening', function (e) {
      if (clearing) {
        clearing = false;
        e.preventDefault();
      }
    });
    $(this.element).on('select2:clear', function() {
      this.dispatchEvent(new Event('change', {bubbles: true})); // fire a native event
    });

    $(this.element).on('select2:open', function(e) {
      document.querySelector(`[aria-controls="select2-${e.target.id.replace(/[\[\]]/g, '')}-results"]`).focus();
      
      // Get the current selection text and populate the search field
      const selectedText = $(this).find(':selected').text();
      if (selectedText) {
        setTimeout(() => {
          const searchField = document.querySelector('.select2-search__field');
          if (searchField) {
            searchField.value = selectedText;
          }
        }, 0);
      }
    });

    if (this.data.get('keypressOpen')) {
      $(this.element).closest('div').find('.select2-selection').on('keypress', function(e) {
        if (e.key == 13 || e.key == 27) {
          return;
        }
        $(e.target).closest('div').find('.select2-hidden-accessible').select2('open');
        setTimeout(function () {
          $(e.target).closest('div').find('.select2-search__field').focus();
          $(e.target).closest('div').find('.select2-search__field').val(e.key);
        }, 100);
      })
    }
  }
}
